import React,{useEffect, useState, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import AxiosInstance from '../../config/AxiosInstance';
import { Container, Box, Typography, Grid, List, ListItem, ListItemIcon, ListItemText, Button } from '@mui/material'
import { StyledTextfield } from '../../components/styled-components/inputField';
import styled from '@emotion/styled'
import Success from '../../assets/images/success.svg';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ReCaptchaButton from '../registration/ReCaptchaButton';
import { toast } from 'react-toastify';
import Loading from '../../components/common-components/CircularLoading';



function Registration({handleOrderComplete, label}) {
    const isReCaptchaEnabled = process.env.REACT_APP_RECAPTCHA_ENABLED === 'true';
    // eslint-disable-next-line
    const [captchaToken, setCaptchaToken] = useState(null); // Captcha token state
    const [isSubmitting, setIsSubmitting] = useState(false); // Submitting state
    const reDat = JSON.parse(sessionStorage.getItem('registration_data'))
   
    // eslint-disable-next-line
    const resitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
    const [regData, setRegData] = useState({
        first_name:reDat ? reDat.first_name : '',
        last_name:reDat? reDat.last_name : '',
        phone_number:reDat? reDat.phone_number : '',
        email:reDat? reDat.email : '',
        zip_code:reDat? reDat.zip_code : ''
    }); // the registration data object. this will be sent to the api when "view pricing for bigbox" button is pressed
    const [zipError, setZipError] = useState(""); // zip error state
    const [validZip, setValidZip] = useState([]); // valid zips state
    const [firstNameError, setFirstNameError] = useState(""); // first name error state
    const [lastNameError, setLastNameError] = useState('');// last name error state
    const [phoneError, setPhoneError] = useState(''); // phone error state
    const [emailError, setEmailError] = useState(''); // email error state
    const effectExecutedRef = useRef(false); // value to keep the useEffect from executing more than one time
    const navigate = useNavigate(); // navigation function to navigate to desired page of the app

    // function to display the phone number in (xxx) xxx-xxxx format
    const formatDisplayPhoneNumber = (value) => {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
      };

    // this useEffect is executed once to get the bigbox info from the api
    useEffect(()=>{
        handleOrderComplete(false); // this makes sure that the order isn't completed yet when the user initially loads this page
        // funtion to get the valid zip value from the big box info
        const getZip = async ()=>{
          if (effectExecutedRef.current) return;
          effectExecutedRef.current = true; // making this value true here will prevent this useEffect from running more than one time
          try{
            const response = await AxiosInstance.get("/bigbox-info"); // api call
            sessionStorage.setItem('bigboxinfo',JSON.stringify(response.data)); // the response is stored in the session storage so that we dont need to call the api frequently
            // makes sure the "delivery_rates" property from the response object is an array just in case
            if (Array.isArray(response.data.delivery_rates)) {
              const zipCodes = response.data.delivery_rates.map(entry => entry.zip); 
              setValidZip(zipCodes); // valid zip codes are fetched from the api and set successfully
            } else {
              toast.error('Unexpected response format');
            }
          }
          // catches if theres any error in the api call and displays to the toast
          catch(error){
            toast.error(error)
          }
        };
        // call of the getZip function
        getZip();
        // eslint-disable-next-line
      },[])

      // function to send the data to the api for creating leads
      const sendData= async(data)=>{
        try{
          const response = await AxiosInstance.post("/bigbox-create-leads",data); // api call to post the data
          // lead_id from the response is set to the session storage
          if (response.data.message === "Leads info saved successfully") {
            sessionStorage.setItem('lead_id', response.data.data.lead_id);
          } 
        }
        // catches if theres any error in the api call and displays in the toast
        catch(error){
            toast.error(error)        
        }
      }

      const handleContinue = async (token) => {
        if (!sessionStorage.getItem('bigboxinfo')) {
            toast.error('Error: We are unable to provide quote now. Please call us for a quote.');
            // window.location.reload()
            return; // Exit early if 'bigboxinfo' is not found
        }
        if (JSON.parse(sessionStorage.getItem('bigboxinfo')).error) {
            toast.error('Error: We are unable to provide quote now. Please call us for a quote.');
            return;
        }
        let isValid = true;
       
        // Validate zip code
        if (validZip.includes(regData.zip_code)) {
            setZipError('');
        } else {
            setZipError("Big Box Storage is not available for this zip code");
            isValid = false;
        }
    
        // Validate first name
        if (!regData.first_name.trim()) {
            setFirstNameError('First Name Required');
            isValid = false;
        } else {
            setFirstNameError('');
        }
    
        // Validate last name
        if (!regData.last_name.trim()) {
            setLastNameError('Last Name Required');
            isValid = false;
        } else {
            setLastNameError('');
        }
    
        // Validate email
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(regData.email)) {
            setEmailError("Invalid email format");
            isValid = false;
        } else {
            setEmailError('');
        }
    
        // Validate phone number
        const phonePattern = /^(\d{10})$/;
        if (!phonePattern.test(regData.phone_number)) {
            setPhoneError("Phone number must be 10 digits");
            isValid = false;
        } else {
            setPhoneError('');
        }
    
        // Only perform reCAPTCHA validation if it is enabled
        if (isValid) {
            setIsSubmitting(true);
            const isReCaptchaEnabled = process.env.REACT_APP_RECAPTCHA_ENABLED === 'true';
    
            if (isReCaptchaEnabled) {
                setCaptchaToken(token);
                try {
                    const url = process.env.REACT_APP_BIGBOX_GLOBAL_URL + '/process-recaptcha';
                    
                    // Create the form data object
                    const formData = new URLSearchParams();
                    formData.append('secret', process.env.REACT_APP_RECAPTCHA_SECRET_KEY);
                    formData.append('token', token);
                
                    // Send the request using fetch
                    const response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'x-api-key': process.env.REACT_APP_BIGBOX_API_KEY,  // Add the API key header
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        body: formData
                    });
                
                    // Check if the response is ok (status code 200-299)
                    if (response.ok) {
                        // Parse the response JSON
                        const data = await response.json();
            
                        // Check the reCAPTCHA score
                        if (data.success && data.score >= 0.5) {
                            // Score is valid, continue with form submission
                            const cleaned = regData.phone_number.replace(/\D/g, '');
                            const formattedPhoneNumber = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                            sessionStorage.setItem('formatted_phone_number', formattedPhoneNumber);
                            sendData(regData);
                            sessionStorage.setItem('registration_data', JSON.stringify(regData));
                            sessionStorage.setItem('delcalzip', regData.zip_code);
                           // toast.success('ReCaptcha verification passed!');
                           setTimeout(() => {
                            navigate(`/bigbox-quote`);
                           }, 500);
                        } else {
                            // Score is too low, show an error message or handle accordingly
                            toast.error('ReCaptcha verification failed');
                            setIsSubmitting(false);
                        }
                    } else {
                        toast.error('HTTP Error: ' + response.status);
                        setIsSubmitting(false);
                    }
                } catch (e) {
                    toast.error('Fetch error');
                    setIsSubmitting(false);
                }
            } else {
                // If reCAPTCHA is not enabled, proceed with form submission directly
                const cleaned = regData.phone_number.replace(/\D/g, '');
                const formattedPhoneNumber = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                sessionStorage.setItem('formatted_phone_number', formattedPhoneNumber);
                sendData(regData);
                sessionStorage.setItem('registration_data', JSON.stringify(regData));
                sessionStorage.setItem('delcalzip', regData.zip_code);
             //   toast.success('Form submitted successfully!');
             setTimeout(() => {
                navigate(`/bigbox-quote`);
               }, 500);
            }
        }
    };
    
      // function to handle the changes in the registration data which is triggered by entering values in the field
      const handleChange = (input) => (e) => {
        // eslint-disable-next-line
        const { name, value } = e.target;
        setRegData({
          ...regData,
          [input]: input === 'phone_number' ? value.replace(/\D/g, '').slice(0,10) : value
        });
      };
    // const handleCaptchaVerify = (token) => {
    //     setCaptchaToken(token);
    // };
    

    // function for button stylings
    const StyledButton = styled(Button)(({ theme, variant }) => {
        return {
            '&.MuiButtonBase-root': {
                backgroundColor: variant === 'contained' ? theme.palette.secondary.main : 'transparent',
                border: `1px solid ${theme.palette.secondary.main}`,
                color: variant === 'contained' ? theme.palette.common.white : theme.palette.secondary.main,
                padding: '12px 24px',
                borderRadius: '10px',
                fontFamily: `'program', sans- serif`,
                fontSize: '1.35rem',
                fontWeight: '700',
                letterSpacing: '1px',
                textTransform: 'capitalize',
                '&:hover': {
                    backgroundColor: variant === 'contained' ? '#cc5a00' : null
                }
            }
        }
    })

    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
            {isSubmitting ? <Loading></Loading> : 
            <Box py={{ xs: 5, md: 10 }} bgcolor={'background.grey1'}>
                <Container fixed>
                    <Box width={{xs:'100%',md:'75%'}} m={'auto'}>
                    <Grid container spacing={2.5}>
                        <Grid item xs={12} md={12}>
                        <Box
                        margin={'auto'} width={{ xs: '100%', md: '100%' }} >
                        <Box>
                            <Typography mb={{ xs: 1, sm: 2 }} variant='h1' color={'primary.dark'} textAlign={{ xs: 'center' }}>{label.header}</Typography>
                            <Grid mb={{ xs: 1, sm: 2 }} container rowSpacing={0} columnSpacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <List dense>
                                        <ListItem sx={{ color: 'primary.light' }} >
                                            <ListItemIcon sx={{minWidth:'36px'}}>
                                                <Typography component={'img'} src={Success} alt='Success' sx={{width:'24px'}}/>
                                            </ListItemIcon >
                                            <ListItemText
                                                primary={label.bullet1left}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <List dense >
                                        <ListItem sx={{ color: 'primary.light' }} >
                                            <ListItemIcon sx={{minWidth:'36px'}}>
                                                <Typography component={'img'} src={Success} alt='Success' sx={{width:'24px'}}/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={label.bullet1right}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <List dense >
                                        <ListItem sx={{ color: 'primary.light' }} >
                                            <ListItemIcon sx={{minWidth:'36px'}}>
                                                <Typography component={'img'} src={Success} alt='Success' sx={{width:'24px'}}/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={label.bullet2left}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <List dense >
                                        <ListItem sx={{ color: 'primary.light' }} >
                                            <ListItemIcon sx={{minWidth:'36px'}}>
                                                <Typography component={'img'} src={Success} alt='Success' sx={{width:'24px'}}/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={label.bullet2right}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                            {/* Form */}
                            <Box component='form'>
                                <Box p={{ xs: 2, md: 4 }} bgcolor={'common.white'} boxShadow={2} borderRadius={'16px'} border={'1px solid #EFEFEF'}>
                                    <Grid container rowSpacing={2} columnSpacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <Box>
                                                <Typography variant='label1' component={'label1'} color={'text.label'}>First Name</Typography>
                                                <StyledTextfield id="first-name" className='styled-textfield' variant="outlined" placeholder='Enter First Name' fullWidth value={regData.first_name} onChange={handleChange('first_name')} autoFocus/>
                                                {firstNameError && <Typography variant='error'>{firstNameError}</Typography>}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box>
                                                <Typography variant='label1' component={'label1'} color={'text.label'}>Last Name</Typography>
                                                <StyledTextfield id="last-name" className='styled-textfield' variant="outlined" placeholder='Enter Last Name' fullWidth value={regData.last_name} onChange={handleChange('last_name')}/>
                                                {lastNameError && <Typography variant='error'>{lastNameError}</Typography>}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box>
                                                <Typography variant='label1' component={'label1'} color={'text.label'}>Email</Typography>
                                                <StyledTextfield id="email" className='styled-textfield' variant="outlined" placeholder='Enter Email' fullWidth value={regData.email} onChange={handleChange('email')}/>
                                                {emailError && <Typography variant='error'>{emailError}</Typography>}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box>
                                                <Typography variant='label1' component={'label1'} color={'text.label'}>Phone Number</Typography>
                                                <StyledTextfield 
                                                    id="phone-number" 
                                                    className='styled-textfield' 
                                                    variant="outlined" 
                                                    placeholder='Enter Phone Number' 
                                                    fullWidth value={regData.phone_number} 
                                                    onChange={handleChange('phone_number')}
                                                    autoComplete='off'
                                                    inputProps={{
                                                        value:formatDisplayPhoneNumber(regData.phone_number),
                                                        form: {
                                                            autocomplete: 'off',
                                                          },
                                                    }} // prevents the auto fill of this field
                                                />
                                                {phoneError && <Typography variant='error'>{phoneError}</Typography>}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box>
                                                <Typography variant='label1' component={'label1'} color={'text.label'}>Delivery Zip Code</Typography>
                                                <StyledTextfield id="zip-code" className='styled-textfield' variant="outlined" placeholder='Enter Zip Code' fullWidth value={regData.zip_code} onChange={handleChange('zip_code')}/>
                                                {zipError && <Typography variant='error'>{zipError}</Typography>}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box py={2.5} textAlign={'center'}>
                                               {isReCaptchaEnabled ? (
                                                 <ReCaptchaButton onSubmit={handleContinue} />
                                             ) : (
                                                 <StyledButton variant='contained' onClick={handleContinue} disabled={isSubmitting}>
                                                     {isSubmitting ? 'Submitting...' : label.button}
                                                 </StyledButton>
                                              )}
                               </Box>
                               
                            </Box>
                        </Box>
                    </Box>
                        </Grid>
                    </Grid>
                    </Box>                  
                </Container>
            </Box>}
        </GoogleReCaptchaProvider>
    )
}

export default Registration