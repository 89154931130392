import React, { useEffect, useState } from 'react';
import AxiosInstance from '../../config/AxiosInstance';
import { useParams } from 'react-router-dom';
import RedirectLoading from '../common-components/RedirectLoading';
import { toast } from 'react-toastify';

function PaynowRedirect() {
    const {quote_id} = useParams();
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [isCalculated, setIsCalculated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const payment_link = process.env.REACT_APP_PAYMENT_URL
    const key_name = process.env.REACT_APP_PAYMENT_API_KEY_NAME
    const key_value = process.env.REACT_APP_PAYMENT_API_KEY_VALUE
  
    useEffect(()=>{
      const fetchQuoteDetails = async () => {
        let data = false;
        if (sessionStorage.getItem('quoteData') === null) {
          try {
            const response = await AxiosInstance.get('/bigbox-quote', { params: { "quote_id": quote_id } });
            const quoteDetails = response.data.quotes;
            if(quoteDetails){
              const calcData = {
                zip_code: (quoteDetails.customer_zip).toString(),
                delivery_date: quoteDetails.delivery_date,
                number_of_boxes: (quoteDetails.number_of_boxes).toString(),
                number_of_months_needed: (quoteDetails.number_of_months_needed).toString(),
                promotion_code: quoteDetails.promotion_code === null ? '' : quoteDetails.promotion_code,
                promotion_code_value: quoteDetails.promotion_code_value,
                rental_rate: Number(quoteDetails.rental_rate).toFixed(2),
                padlock_quantity: (quoteDetails.padlock_quantity).toString(),
                padlock_price: Number(quoteDetails.padlock_price).toFixed(2),
                protection_plan_quantity: (quoteDetails.protection_plan_quantity).toString(),
                protection_plan_price: Number(quoteDetails.protection_plan_price).toFixed(2),
                delivery_fee: Number(quoteDetails.delivery_fee).toFixed(2),
              };
              sessionStorage.setItem('calcData', JSON.stringify(calcData));
              sessionStorage.setItem('quoteData', JSON.stringify(quoteDetails));
              setIsDataFetched(true);
              data = true;
            }
            
          } catch (error) {
            data = false;
            toast.error('Error fetching quote details');
          }
        } else {
          setIsDataFetched(true);
        }
        return data;
      };
  
      const fetchCalcData = async () => {
        let calculation = false;
        const calculationData = JSON.parse(sessionStorage.getItem('calcData'));
        if (calculationData) {
          try {
            const response = await AxiosInstance.post("/bigbox-calculate-cost", calculationData);
            sessionStorage.setItem('calcResult', JSON.stringify(response.data.data));
            setIsCalculated(true);
            calculation = true;
          } catch (error) {
            calculation = false;
            toast.error('Error fetching calc data');
          }
        } else {
          setIsCalculated(true);
        }
        return calculation;
      };

      const makePayment = async () => {
        const quoteDetails = JSON.parse(sessionStorage.getItem('quoteData'));
        const calcResult = JSON.parse(sessionStorage.getItem('calcResult'));
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = payment_link;

        const params = {
          'userid': '10001',
            'zip_code': quoteDetails.zip_code,
            'delivery_date': quoteDetails.delivery_date,
            'number_of_boxes': quoteDetails.number_of_boxes,
            'number_of_months_needed': quoteDetails.number_of_months_needed,
            'delivery_time': quoteDetails.delivery_time,
            'storage_location': quoteDetails.storage_location,
            'pickup_date': quoteDetails.pickup_date,
            'referral_source': '',
            'rental_rate': Number(Number(quoteDetails.rental_rate).toFixed(2)),
            'delivery_fee': Number(quoteDetails.delivery_fee).toFixed(2),
            'one_time_fee': Number(Number(quoteDetails.one_time_fee).toFixed(2)),
            'merchandise_fee': Number(Number(quoteDetails.merchandise_fee).toFixed(2)),
            'tax': Number(Number(quoteDetails.tax).toFixed(2)),
            'total': Number(Number(quoteDetails.total).toFixed(2)),
            'cost[tax]': (calcResult.cost.tax.toFixed(2)).toString(),
            'cost[total]': ((calcResult.cost.final_total).toFixed(2)).toString(),
            'cost[monthly]': (calcResult.cost.monthly.toFixed(2)).toString(),
            'cost[delivery]': (calcResult.cost.delivery.toFixed(2)).toString(),
            'cost[oneTimeFee]': (calcResult.cost.oneTimeFee.toFixed(2)).toString(),
            'cost[merchandise]': (calcResult.cost.merchandise.toFixed(2)).toString(),
            'cost[final_total]': (calcResult.cost.final_total.toFixed(2)).toString(),
            'proration[firstMonthStartDate]': calcResult.proration.firstMonthStartDate,
            'proration[firstMonthEndDate]': calcResult.proration.firstMonthEndDate,
            'proration[firstMonthRent]': (calcResult.proration.firstMonthRent.toFixed(2)).toString(),
            'proration[nextMonthStartDate]': calcResult.proration.nextMonthStartDate,
            'proration[nextMonthEndDate]': calcResult.proration.nextMonthEndDate,
            'proration[nextMonthRent]': (calcResult.proration.nextMonthRent.toFixed(2)).toString(),
            'proration[prorationStartDate]': calcResult.proration.prorationStartDate,
            'proration[prorationEndDate]': calcResult.proration.prorationEndDate,
            'proration[prorationRent]': (calcResult.proration.prorationRent.toFixed(2)).toString(),
            'merchandise_list[9][quantity]': quoteDetails.padlock_quantity,
            'merchandise_list[9][value]': Number(quoteDetails.padlock_price).toFixed(2),
            'idv': (quoteDetails.protection_plan_quantity > 0 ? 1 : 0),
            'prospect[first]': quoteDetails.customer_first_name,
            'prospect[last]': quoteDetails.customer_last_name,
            'prospect[phone][area]': quoteDetails.customer_phone_area,
            'prospect[phone][prefix]': quoteDetails.customer_phone_prefix,
            'prospect[phone][number]': quoteDetails.customer_phone_number,
            'prospect[email]': quoteDetails.customer_email,
            'prospect[address]': quoteDetails.customer_address,
            'prospect[address2]': quoteDetails.customer_address2,
            'prospect[city]': quoteDetails.customer_city,
            'prospect[state]': quoteDetails.customer_state,
            'prospect[zip]': quoteDetails.customer_zip,
            [key_name]: key_value, // Add the key_name and key_value pair to the params
            'pid':quoteDetails.bigbox_prospect_id
        };
        for (const key in params) {
          if (params.hasOwnProperty(key)) {
              const hiddenField = document.createElement('input');
              hiddenField.type = 'hidden';
              hiddenField.name = key;
              hiddenField.value = params[key];
              form.appendChild(hiddenField);
          }
        }

        document.body.appendChild(form);
        form.submit();
      }
      const redirect = async () => {
        let quoteResponse = await fetchQuoteDetails();
        let calcResponse;

        if (quoteResponse) {
        calcResponse = await fetchCalcData();
        }
        else{
          window.location.reload();
        }

        if (calcResponse && quoteResponse) {
        await makePayment();
        }
        else{
          window.location.reload();
        }

      }
      redirect();
    }, [quote_id]);

    useEffect(()=>{
      if(isDataFetched && isCalculated){
        setIsLoading(false);
      }
    },[isDataFetched, isCalculated]);

    if (isLoading) {
      return (
          <RedirectLoading />
      );
    }
    return null;
}

export default PaynowRedirect;
